import React from "react";
import "../admin/style.css";
import "./style.css";
import { Tabs } from "rsuite";
import BlogsManage from '../BlogsManage/index';
import CaseStudyManage from '../CaseStudyManage/index';
import EventsManage from '../EventsManage/index';

export const ContentManagement = () => {
  return (
    <div className="contentContainer" style={{ overflow: "auto" }}>
      <text className="text-2xl font-bold">Content Management </text>

      <div className="separator-horizontal" />

      <text className="text-gray-600">Manage all the AHC content here</text>

      <div className="separator-horizontal" />
      <div className="separator-horizontal" />

      <div className="flex flex-row justify-between w-11/12 h-12 items-center">
        <Tabs
          defaultActiveKey="1"
          appearance="subtle"
          className="w-full h-full"
        >
          <Tabs.Tab eventKey="1" title="Blogs">
            <BlogsManage />
          </Tabs.Tab>
          <Tabs.Tab eventKey="2" title="Case Studies">
            <CaseStudyManage />
          </Tabs.Tab>
          <Tabs.Tab eventKey="3" title="Events">
            <EventsManage />
          </Tabs.Tab>
        </Tabs>
      </div>
    </div>
  );
};