import React from "react";
import "../page-style.css";
import "./style.css";
import userApi from "../../api/UserApi";
import { useAuth } from "../../Provider/Authcontext";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { Button, Checkbox, Form, Input } from "antd";

export const Login = () => {

  const { login } = useAuth();
  const nav = useNavigate()
  const onFinish = async (values) => {
    const { emailAddress, password } = values; 
    try {
      const resp = await login(emailAddress, password);  
      if(resp){
        nav("/adminDashboard");
      } 
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className={"mainContainer"}>
      <div className={"innerContainer "}>
        <img
          className={"img"}
          src={require("../../assets/images/AHC-logo.png")}
          alt="img"
        />
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="form"
        >
          <Form.Item
            label="Email address"
            name="emailAddress"
            rules={[
              {
                required: true,
                message: "Please input your email!",
                type: "email",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="remember"
            valuePropName="checked"
            wrapperCol={{ offset: 8, span: 16 }}
          >
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button htmlType="submit" className="buttonSubmit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
      <Toaster />
    </div>
  );
};
