import React, { useState, useEffect } from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, Typography, Divider } from "@mui/material";
import { Email, Phone, Person, Engineering, Info, Group, Delete } from "@mui/icons-material";
import eventApi from "../../api/EventApi";

const AttendeeModal = ({ attendees }) => {
    const [openRSVPModal, setOpenRSVPModal] = useState(false);
    const [updatedAttendees, setUpdatedAttendees] = useState([]);
    const [attendeeCount, setAttendeeCount] = useState(0);
    const [attendeeRemovedMessage, setAttendeeRemovedMessage] = useState("");

    const handleOpenRSVPModal = () => {
        setOpenRSVPModal(true);
    };

    const handleCloseRSVPModal = () => {
        setOpenRSVPModal(false);
    };

    const handleRoleDisplay = async () => {
        try {
            const companyMembers = await eventApi.getCompanyMembers();
            const updatedAttendeesWithRole = attendees.map(attendee => {
                const matchedMember = companyMembers.find(member => member.userId === attendee.userId);
                if (matchedMember) {
                    return {
                        ...attendee,
                        role: matchedMember.role,
                    };
                }
                return attendee;
            });

            setUpdatedAttendees(updatedAttendeesWithRole);
            setAttendeeCount(updatedAttendeesWithRole.length);
        } catch (error) {
            console.log(error);
        }
    };

    const handleRemoveAttendee = async (userId, eventId, email) => {
        const data = {
          userId: userId,
          eventId: eventId,
          attendeeEmail: email,
        };

        try {
            const removedAttendee = await eventApi.removeRsvp(data);
            if (removedAttendee) {
                const updatedAttendeesAfterRemove = updatedAttendees.filter(attendee => attendee.userId !== userId);
                setUpdatedAttendees(updatedAttendeesAfterRemove);
                setAttendeeCount(updatedAttendeesAfterRemove.length);
                setAttendeeRemovedMessage("Attendee removed successfully.");
            }
        }
        catch (error) {
            console.log(error);
            setAttendeeRemovedMessage("Failed to remove attendee.");
        }
    }

    useEffect(() => {
        if(attendees) {
            handleRoleDisplay();
        }
    }, [attendees]);

    return (
        <div className="mb-4">
            {updatedAttendees && updatedAttendees.length > 0 && (
                <Button variant="contained" style={{backgroundColor: "#2D9687"}} onClick={handleOpenRSVPModal}>
                    Manage RSVP
                </Button>
            )}

            <Dialog open={openRSVPModal} onClose={handleCloseRSVPModal} maxWidth="sm" fullWidth>
                <DialogTitle>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Group fontSize="large" style={{ marginRight: "10px" }} />
                        <Typography variant="h6">
                            Attendees ({attendeeCount})
                        </Typography>
                    </div>
                </DialogTitle>

                {attendeeRemovedMessage && (
                    setTimeout(() => {
                        setAttendeeRemovedMessage("");
                    }, 4000),
                    <Typography variant="body1" style={{ textAlign: "center", color: "green" }}>
                        {attendeeRemovedMessage}
                    </Typography>
                )}

                <DialogContent>
                    {updatedAttendees && updatedAttendees.length > 0 ? (
                        <Grid container spacing={2}>
                            {updatedAttendees.map((attendee, index) => (
                                <React.Fragment key={attendee.id}>
                                    <Grid item xs={12} style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                        <img 
                                            src={attendee?.user?.profilePic} 
                                            alt="profile"
                                            style={{ width: "110px", height: "110px", borderRadius: "10%", marginRight: "15px" }} 
                                        />
                                        <div className="w-1/2">
                                            <Typography variant="body1">
                                                <Person fontSize="small" style={{ verticalAlign: "middle", marginRight: "5px", color: "#2D9687" }} />
                                                {attendee?.user?.firstName} {attendee?.user?.lastName}
                                            </Typography>
                                            <Typography variant="body1">
                                                <Email fontSize="small" style={{ verticalAlign: "middle", marginRight: "5px", color: "#2D9687" }} />
                                                {attendee?.user?.emailAddress}
                                            </Typography>
                                            <Typography variant="body1">
                                                <Phone fontSize="small" style={{ verticalAlign: "middle", marginRight: "5px", color: "#2D9687" }} />
                                                {attendee?.user?.phoneNumber}
                                            </Typography>
                                            <Typography variant="body1">
                                                <Engineering fontSize="small" style={{ verticalAlign: "middle", marginRight: "5px", color: "#2D9687" }} />
                                                {attendee.role || "N/A"}
                                            </Typography>
                                            {attendee?.rsvpdetails && (
                                                <Typography variant="body2" color="textSecondary">
                                                    <Info fontSize="small" style={{ verticalAlign: "middle", marginRight: "5px", color: "#2D9687" }} />
                                                    {attendee?.rsvpdetails}
                                                </Typography>
                                            )}
                                        </div>

                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            startIcon={<Delete />}
                                            style={{ marginLeft: "auto", color: "white", backgroundColor: "#2D9687" }}
                                            onClick={() => handleRemoveAttendee(attendee.userId, attendee.eventId, attendee.attendeeEmail)}
                                        >
                                            Remove
                                        </Button>
                                    </Grid>
                                    {index < updatedAttendees.length - 1 && <Divider style={{ width: "100%" }} />}
                                </React.Fragment>
                            ))}
                        </Grid>
                    ) : (
                        <Typography>No attendees found.</Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={handleCloseRSVPModal} 
                        variant="contained"
                        style={{ marginLeft: "auto", color: "white", backgroundColor: "#2D9687" }}>
                            Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AttendeeModal;
