import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { PlusOutlined } from "@ant-design/icons";
import { Upload, notification } from "antd";
import { Input } from "rsuite";
import utilApi from '../../api/UtilApi';
import blogApi from "../../api/BlogApi";
import {
    EditorState,
    convertFromHTML,
    ContentState,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { AtomicBlockUtils } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./style.css";

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

export const EditBlog = ({ setDisplayBlogView, blogData }) => {
    const [fileList, setFileList] = useState([]);
    const [previewImage, setPreviewImage] = useState(blogData?.blogImage || "");
    const [blogTitle, setBlogTitle] = useState(blogData?.blogTitle || "");

    const [editorState, setEditorState] = useState(() => {
        if (blogData?.blogText) {
            try {
                const blocksFromHTML = convertFromHTML(blogData.blogText);
                const contentState = ContentState.createFromBlockArray(
                    blocksFromHTML.contentBlocks,
                    blocksFromHTML.entityMap
                );
                return EditorState.createWithContent(contentState);
            } catch (e) {
                const contentState = ContentState.createFromText(blogData.blogText);
                return EditorState.createWithContent(contentState);
            }
        }
        return EditorState.createEmpty();
    });

    const [isPreviewVisible, setIsPreviewVisible] = useState(false);
    const [htmlPreview, setHtmlPreview] = useState("");

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        const baseString = file.preview.split(",")[1];
        const imageUrl = await utilApi.convertBase64ToPng(baseString);
        if (imageUrl) {
            setPreviewImage(imageUrl);
        }
    };

    const handleChange = async ({ fileList }) => {
        setFileList(fileList);
        if (fileList.length > 0) {
            const file = fileList[fileList.length - 1];
            handlePreview(file);
        }
    };

    const handleRemove = () => {
        setFileList([]);
        setPreviewImage("");
    };

    const handlePostBlog = async () => {
        try {
            const contentState = editorState.getCurrentContent();
            const htmlContent = stateToHTML(contentState);

            if (!blogTitle || !htmlContent || !previewImage) {
                notification.error({
                    message: 'Incomplete Information',
                    description: 'Please fill in all required fields: Title, Body, and Cover Image.',
                });
                return;
            }

            const postedData = {
                userId: 104,
                blogTitle: blogTitle,
                blogText: htmlContent,
                blogImage: previewImage,
            };
            const response = await blogApi.postBlogData(postedData);
            if (response) {
                notification.success({
                    message: 'Success',
                    description: 'Blog posted successfully!',
                });
                setTimeout(() => {
                    handleCloseBlogView();
                }, 2000);
            }
        } catch (error) {
            console.error("Error posting blog:", error);
            notification.error({
                message: 'Failure',
                description: 'Failed to post blog.',
            });
        }
    };

    const handleEditBlog = async () => {
        try {
            const contentState = editorState.getCurrentContent();
            const htmlContent = stateToHTML(contentState);
    
            if (!blogTitle || !htmlContent || !previewImage) {
                notification.error({
                    message: 'Incomplete Information',
                    description: 'Please fill in all required fields: Title, Body, and Cover Image.',
                });
                return;
            }
    
            const updatedData = {
                id: blogData.id,
                userId: blogData.userId,
                blogTitle: blogTitle,
                blogText: htmlContent,
                blogImage: previewImage,
            };

            const response = await blogApi.updateBlogData(updatedData);
            if (response) {
                notification.success({
                    message: 'Success',
                    description: 'Blog updated successfully!',
                });
                setTimeout(() => {
                    handleCloseBlogView();
                }, 2000);
            }
        } catch (error) {
            console.error("Error updating blog:", error);
            notification.error({
                message: 'Failure',
                description: 'Failed to update blog.',
            });
        }
    };

    const handleDeleteBlog = async () => {
        try {
            const response = await blogApi.deleteBlogData(blogData?.id);
            if (response) {
                notification.success({
                    message: 'Success',
                    description: 'Blog deleted successfully!',
                });
                setTimeout(() => {
                    handleCloseBlogView();
                }, 2000);
            } else {
                notification.error({
                    message: 'Failure',
                    description: 'Failed to delete blog.',
                });
            }
        } catch (error) {
            console.error("Error deleting blog:", error);
            notification.error({
                message: 'Failure',
                description: 'Failed to delete blog.',
            });
        }
    };

    const handleImageUpload = async (file) => {
        try {
            const base64 = await getBase64(file);
            const data = await utilApi.convertBase64ToPng(base64.split(",")[1]);

            if (data) {
                setEditorState((prevState) => {
                    const contentState = prevState.getCurrentContent();
                    const contentStateWithEntity = contentState.createEntity(
                        "IMAGE",
                        "IMMUTABLE",
                        { src: data }
                    );
                    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
                    const newEditorState = AtomicBlockUtils.insertAtomicBlock(
                        prevState,
                        entityKey,
                        " "
                    );
                    return newEditorState;
                });
            }
        } catch (error) {
            console.error("Error uploading image:", error);
            notification.error("Upload failed");
        }
    };

    const handleCloseBlogView = () => {
        setDisplayBlogView(false);
    };

    const handlePreviewBlog = () => {
        const contentState = editorState.getCurrentContent();
        const htmlContent = stateToHTML(contentState);
        setHtmlPreview(htmlContent);
        setIsPreviewVisible(true);
    };

    const handleBlogTextChange = (editorState) => {
        setEditorState(editorState);
    }

    return (
        <>
            <div className="flex flex-row">
                <IoMdClose
                    className="cursor-pointer"
                    size={18}
                    onClick={handleCloseBlogView}
                />
                <div className="separator-vertical" />
                <text className="text-2xl font-bold">{blogData ? "Edit Blog" : "Add New Blog"}</text>
            </div>

            <div className="separator-horizontal" />

            <text className="text-gray-600">
                {blogData ? "Modify the contents of your blog here" : "Create Blogs for your user to see"}
            </text>

            <div className="separator-horizontal" />
            <div className="separator-horizontal" />

            <text className="font-bold">Cover</text>

            <div className="separator-horizontal" />

            <Upload
                listType="picture-card"
                fileList={fileList}
                onChange={handleChange}
                onRemove={handleRemove}
                beforeUpload={() => false}
                showUploadList={false}
            >
                <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                    {previewImage && (
                        <img
                            src={previewImage}
                            alt="Cover"
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                opacity: 0.7,
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                zIndex: 1,
                            }}
                        />
                    )}
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                            color: 'white',
                            fontSize: '20px',
                            position: 'absolute',
                            backgroundColor: previewImage ? 'rgba(0, 0, 0, 0.1)' : '#2d9687',
                            top: 0,
                            left: 0,
                            width: '100%',
                            zIndex: 2,
                        }}
                    >
                        <div>
                            <PlusOutlined />
                            <div style={{ marginTop: 8 }}>Upload</div>
                        </div>
                    </div>
                </div>
            </Upload>
            <div style={{ width: "80%" }}>
                <Input
                    value={previewImage}
                    onChange={(value) => setPreviewImage(value)}
                    size="md"
                />
            </div>

            <div className="separator-horizontal" />

            <div style={{ width: "80%" }}>
                <text className="font-bold">Title</text>
                <div className="separator-horizontal" />
                <Input
                    value={blogTitle}
                    onChange={(value) => setBlogTitle(value)}
                    size="md"
                />
                <div className="separator-horizontal" />

                <text className="font-bold">Body</text>
            </div>

            <div className="editorWrapper">
                <Editor
                    editorState={editorState}
                    onEditorStateChange={handleBlogTextChange}
                    placeholder="Write your blog content here..."
                    toolbar={{
                        options: ["inline", "blockType", "fontSize", "list", "textAlign", "history", "embedded", "image"],
                        image: {
                            uploadCallback: handleImageUpload,
                            alt: { present: true, mandatory: false },
                        },
                    }}
                />
            </div>

            <div className="justify-end mt-3 mb-10">
                <div className="flex flex-row">
                    <button className="btnftn" onClick={blogData ? handleEditBlog : handlePostBlog}>
                        {blogData ? "Update Blog" : "Post Blog"}
                    </button>

                    {blogData && (
                        <button className="btnftn ml-3" onClick={() => handleDeleteBlog()}>
                            Delete Blog
                        </button>
                    )}

                    <button className="btnftn ml-3" onClick={handlePreviewBlog}>
                        Preview Blog Text
                    </button>
                </div>
            </div>

            {isPreviewVisible && (
                <div className="mobile-preview-container">
                    <div className="mobile-preview-header">
                        <div className="mobile-preview-notch" />
                    </div>
                    <div className="mobile-preview-content">
                        <div dangerouslySetInnerHTML={{ __html: htmlPreview }} />
                    </div>
                </div>
            )}
        </>
    );
};

export default EditBlog;
