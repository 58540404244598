import React, { useState, useEffect } from "react";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    Typography,
    Divider,
    TextField,
    Avatar
} from "@mui/material";
import { Email, Group, Info, Delete, PersonAdd, ExpandMore, ExpandLess } from "@mui/icons-material";
import eventApi from "../../api/EventApi";

const InvitationModal = ({ invitations, allUsers, eventData }) => {
    const [openInvitationModal, setOpenInvitationModal] = useState(false);
    const [updatedInvitations, setUpdatedInvitations] = useState([]);
    const [invitationCount, setInvitationCount] = useState(0);
    const [invitationRemovedMessage, setInvitationRemovedMessage] = useState("");
    const [inviteeEmail, setInviteeEmail] = useState("");
    const [showAllUsers, setShowAllUsers] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");

    const handleOpenInvitationModal = () => {
        setOpenInvitationModal(true);
    };

    const handleCloseInvitationModal = () => {
        setOpenInvitationModal(false);
    };

    const handleRemoveInvitation = async (inviteId) => {
        try {
            let response = await eventApi.removeInvite(inviteId);
            if (response.success) {
                let updatedInvites = updatedInvitations.filter((invitation) => invitation.id !== inviteId);
                setUpdatedInvitations(updatedInvites);
                setInvitationCount(updatedInvites.length);
                setInvitationRemovedMessage("Invitation removed successfully.");
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleSendInvite = async (email) => {
        const data = {
            inviteeEmail: email,
            eventName: eventData.eventName + " - " + eventData.description,
        };

        try {
            let response = await eventApi.sendEventInvitations(data);
            if (response) {
                await eventApi.addToAttendeeList({
                    eventId: eventData.id,
                    inviteeEmail: email,
                    status: 0
                });

                const newInvitation = {
                    id: response.invitationId,
                    inviteeEmail: email,
                    status: 0
                };
                setUpdatedInvitations([...updatedInvitations, newInvitation]);
                setInvitationCount(invitationCount + 1);
                setInvitationRemovedMessage(`Invitation sent successfully to ${email}.`);
                setInviteeEmail("");
            }
        } catch (error) {
            console.log(error);
            setInvitationRemovedMessage(`Failed to send invitation to ${email}.`);
        }
    };

    const handleManualInvite = () => {
        if (inviteeEmail) {
            handleSendInvite(inviteeEmail);
        }
    };

    useEffect(() => {
        if (invitations) {
            setUpdatedInvitations(invitations);
            setInvitationCount(invitations.length);
        }
    }, [invitations]);

    const filteredUsers = allUsers.filter((user) =>
        user.email.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="mb-4">
            <Button variant="contained" style={{ backgroundColor: "#2D9687" }} onClick={handleOpenInvitationModal}>
                Manage Invitations
            </Button>

            <Dialog open={openInvitationModal} onClose={handleCloseInvitationModal} maxWidth="sm" fullWidth>
                <DialogTitle>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Group fontSize="large" style={{ marginRight: "10px" }} />
                        <Typography variant="h6">
                            Invitations ({invitationCount})
                        </Typography>
                    </div>
                </DialogTitle>

                {invitationRemovedMessage && (
                    <Typography variant="body1" style={{ textAlign: "center", color: "green" }}>
                        {invitationRemovedMessage}
                    </Typography>
                )}

                <DialogContent key={invitationCount}>
                    <Grid container spacing={2}>
                        {updatedInvitations.length > 0 ? (
                            updatedInvitations.map((invitation, index) => (
                                <React.Fragment key={invitation.id}>
                                    <Grid item xs={12} style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                        <div className="w-1/2">
                                            <Typography variant="body1">
                                                <Email fontSize="small" style={{ verticalAlign: "middle", marginRight: "5px", color: "#2D9687" }} />
                                                {invitation.inviteeEmail}
                                            </Typography>
                                            <Typography variant="body1">
                                                <Info fontSize="small" style={{ verticalAlign: "middle", marginRight: "5px", color: "#2D9687" }} />
                                                Status: {invitation.status === 0 ? "Pending" : "Accepted"}
                                            </Typography>
                                        </div>

                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            startIcon={<Delete />}
                                            style={{ marginLeft: "auto", color: "white", backgroundColor: "#2D9687" }}
                                            onClick={() => handleRemoveInvitation(invitation.id)}
                                        >
                                            Remove
                                        </Button>
                                    </Grid>
                                    {index < updatedInvitations.length - 1 && <Divider style={{ width: "100%" }} />}
                                </React.Fragment>
                            ))
                        ) : (
                            <Grid item xs={12}>
                                <Typography
                                    variant="body1"
                                    style={{ textAlign: "center", width: "100%", wordWrap: "break-word" }}
                                >
                                    No invitations found.
                                </Typography>
                            </Grid>
                        )}
                    </Grid>

                    <Divider style={{ margin: "20px 0" }} />

                    <Typography variant="h6" style={{ marginBottom: "10px" }}>Invite Attendees</Typography>
                    
                    <TextField
                        fullWidth
                        label="Enter email to invite"
                        variant="outlined"
                        value={inviteeEmail}
                        onChange={(e) => setInviteeEmail(e.target.value)}
                        style={{ marginBottom: "20px" }}
                    />
                    <div className="mb-3">
                        <Button variant="contained" style={{ backgroundColor: "#2D9687", color: "white", marginRight: 10 }} onClick={handleManualInvite}>
                            Send Invite
                        </Button>

                        <Button variant="contained" style={{ backgroundColor: "#2D9687", color: "white" }} onClick={() => setShowAllUsers(!showAllUsers)}>
                            {showAllUsers ? "Hide Users" : "Show All Users"}
                        </Button>
                    </div>
                    <Divider style={{marginBottom: "20px"}}/>

                    {showAllUsers && (
                        <>
                            <Grid container spacing={2}>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                    <Typography variant="h6" style={{ margin: "15px" }}>List of available users</Typography>
                                    <TextField
                                        fullWidth
                                        label="Search Users by Email"
                                        variant="outlined"
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                        style={{ marginTop: "0", width: "50%", marginLeft: "15px" }}
                                        InputProps={{
                                            style: {
                                                height: "45px"
                                            }
                                        }}
                                    />
                                </div>

                                {filteredUsers.map((user) => (
                                    <Grid item xs={12} key={user.id} style={{ display: "flex", alignItems: "center" }}>
                                        <Avatar
                                            alt={`${user.firstName} ${user.lastName}`}
                                            src={user.profileImage || "/path/to/default/avatar.jpg"}
                                            style={{ marginRight: "10px" }}
                                        />
                                        <div className="w-1/2">
                                            <Typography variant="body1">
                                                <PersonAdd fontSize="small" style={{ verticalAlign: "middle", marginRight: "5px", color: "#2D9687" }} />
                                                {user.firstName} {user.lastName} ({user.email})
                                            </Typography>
                                        </div>
                                        <Button
                                            variant="contained"
                                            style={{ marginLeft: "auto", color: "white", backgroundColor: "#2D9687" }}
                                            onClick={() => handleSendInvite(user.email)}
                                        >
                                            Send Invite
                                        </Button>
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                    )}
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseInvitationModal} variant="contained" style={{ marginLeft: "auto", color: "white", backgroundColor: "#2D9687" }}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default InvitationModal;
