import React, { useState, useEffect } from "react";
import { Upload, message, notification } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Input } from "rsuite";
import { IoMdClose } from "react-icons/io";
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import eventApi from "../../api/EventApi";
import utilApi from "../../api/UtilApi";
import userApi from "../../api/UserApi";
import { TextField, Button } from "@mui/material";
import AttendeeModal from "../AttendeeModal";
import InvitationModal from "../InvitationModal";
import { createTheme, ThemeProvider } from '@mui/material/styles';

const customTheme = createTheme({
    palette: {
      primary: {
        main: '#2D9687',
      },
    },
  });
  

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const EventEdit = ({ setDisplayEventView, eventData }) => {
    const [fileList, setFileList] = useState([]);
    const [attendees, setAttendees] = useState(null);
    const [invitations, setInvitations] = useState(null);
    const [eventId, setEventId] = useState(eventData?.id || "");
    const [allUsers, setAllUsers] = useState([]);
    const [previewImage, setPreviewImage] = useState(eventData?.eventImage || "");
    const [eventName, setEventName] = useState(eventData?.eventName || "");
    const [eventLocation, setEventLocation] = useState(eventData?.eventLocation || "");
    const [description, setDescription] = useState(eventData?.description || "");
    const [startDate, setStartDate] = useState(eventData?.startDate ? new Date(eventData.startDate * 1000) : null);
    const [endDate, setEndDate] = useState(eventData?.endDate ? new Date(eventData.endDate * 1000) : null);
    const [latitude, setLatitude] = useState(eventData?.latitude || "-26.109");
    const [longitude, setLongitude] = useState(eventData?.longitude || "28.052");
    const [eventBookmarks, setEventBookmarks] = useState([]);

    useEffect(() => {
        setFileList([{ url: eventData?.eventImage }]);
    }, [eventData]);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        const baseString = file.preview.split(",")[1];
        const imageUrl = await utilApi.convertBase64ToPng(baseString);
        if (imageUrl) {
            message.success("Image uploaded successfully!");
            setPreviewImage(imageUrl);
        }
    };

    const handleImageChange = async ({ fileList }) => {
        setFileList(fileList);
        if (fileList.length > 0) {
            const file = fileList[fileList.length - 1];
            handlePreview(file);
        }
    };

    const handleRemove = () => {
        setFileList([]);
        setPreviewImage("");
    };

    useEffect(() => {
        const getAttendees = async () => {
            try {
                const response = await eventApi.getEventAttendeesByEvent(eventId);
                setAttendees(response);
            } catch (error) {
                console.log("Error fetching event attendees: ", error);
            }
        };

        const getEventBookmarks = async () => {
            try {
                const response = await eventApi.getEventBookmarks();
                setEventBookmarks(response);
            } catch (error) {
                console.log("Error fetching event bookmarks: ", error);
            }
        };

        const getInvitations = async () => {
            try {
                const response = await eventApi.getEventInvitations();
                setInvitations(response);
            } catch (error) {
                console.log("Error fetching event invitations: ", error);
            }
        };

        const getAllUsers = async () => {
            try {
                const response = await userApi.getAllUsers();
                if(response.data){
                    const users = response?.data.map((user) => {
                        return {
                            id: user.id,
                            firstName: user.firstName,
                            lastName: user.lastName,
                            email: user.emailAddress,
                            profileImage: user.profilePic,
                        };
                    });
                    setAllUsers(users);
                }
            } catch (error) {
                console.log("Error fetching all users: ", error);
            }
        };



        if (eventId) {
            getAttendees();
            getEventBookmarks();
            getInvitations();
            getAllUsers();
        }
    }, [eventId]);

    const missingValues = () => {
        if (!eventName || !eventLocation || !startDate || !endDate || !latitude || !longitude || !previewImage) {
            notification.error({
                message: 'Missing Information',
                description: 'Please fill in all the required fields before submitting.',
            });
            return true;
        }
        return false;
    };

    const handlePostEvent = async () => {
        const missing = missingValues();
        if (missing) return;

        const updatedEventData = {
            eventName,
            eventLocation,
            description,
            startDate: Math.floor(startDate.getTime() / 1000),
            endDate: Math.floor(endDate.getTime() / 1000),
            latitude,
            longitude,
            eventStatus: true,
            streamActivityId: "string",
            eventImage: previewImage
        };

        try {
            const response = await eventApi.createEvent(updatedEventData);

            if (response || response.data !== null) {
                notification.success({
                    message: 'Success',
                    description: 'Event created successfully!',
                });
                setTimeout(() => {
                    handleCloseEventView();
                }, 4000);
            } else {
                notification.error({
                    message: 'Failure',
                    description: 'Failed to create event.',
                });
            }
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'An error occurred while creating the event.',
            });
        }
    };

    const handleEditEvent = async () => {
        const missing = missingValues();
        if (missing) return;

        const updatedEventData = {
            id: eventId,
            eventName,
            eventLocation,
            description,
            startDate: Math.floor(startDate.getTime() / 1000),
            endDate: Math.floor(endDate.getTime() / 1000),
            latitude,
            longitude,
            eventStatus: true,
            streamActivityId: "string",
            eventImage: previewImage
        };
        console.log(updatedEventData);

        try {
            const response = await eventApi.updateEvent(updatedEventData);
            if (response.success) {
                notification.success({
                    message: 'Success',
                    description: 'Event updated successfully!',
                });
                setTimeout(() => {
                    handleCloseEventView();
                }, 4000);
            }
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'An error occurred while updating the event.',
            });
        }
    };

    const handleDeleteEvent = async () => {

        for (const element of attendees) {
            const attendee = element;
            try {
                await eventApi.deleteAttendeeById(attendee.id);
            } catch (error) {
               console.log("Error deleting attendee: ", error);
            }
        }

        if (eventBookmarks[0].eventId === eventId) {
            try {
                await eventApi.deleteEventBookmarkById(eventBookmarks[0].id);
            } catch (error) {
                console.log("Error deleting event bookmark: ", error);
            }
        }
        

        try {
            if (eventData?.id) {
                const response = await eventApi.deleteEvent(eventData.id);
                if (response.success) {
                    notification.success({
                        message: 'Success',
                        description: 'Event deleted successfully!',
                    });
                    setTimeout(() => {
                        handleCloseEventView();
                    }, 4000);
                } else {
                    notification.error({
                        message: 'Failure',
                        description: 'Failed to delete event.',
                    });
                }
            }
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'An error occurred while deleting the event.',
            });
        }
    };

    const handleCloseEventView = () => {
        setDisplayEventView(false);
    };

    const handleMapClick = (e) => {
        setLatitude(e.latlng.lat);
        setLongitude(e.latlng.lng);
    };

    const MapClickHandler = () => {
        useMapEvents({
            click: handleMapClick,
        });
        return null;
    };

    return (
        <div className="mb-4">
            <div className="flex flex-row">
                <IoMdClose
                    className="cursor-pointer"
                    size={18}
                    onClick={handleCloseEventView}
                />
                <div className="separator-vertical" />
                <span className="text-2xl font-bold">{eventData ? "Edit Event" : "Post Event"}</span>
            </div>

            <div className="separator-horizontal" />

            <span className="text-gray-600">
                {eventData ? "Edit the details of your event" : "Create a new event by providing the necessary information below"}
            </span>

            <div className="separator-horizontal" />

            <span className="font-bold">Event Image</span>

            <div className="separator-horizontal" />

            <Upload
                listType="picture-card"
                fileList={fileList}
                onChange={handleImageChange}
                onRemove={handleRemove}
                beforeUpload={() => false}
                showUploadList={false}
            >
                <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                    {previewImage && (
                        <img
                            src={previewImage}
                            alt="Event"
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                opacity: 0.7,
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                zIndex: 1,
                            }}
                        />
                    )}
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                            color: 'white',
                            fontSize: '20px',
                            backgroundColor: previewImage ? 'rgba(0, 0, 0, 0.1)' : '#2d9687',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            zIndex: 2,
                        }}
                    >
                        <div>
                            <PlusOutlined />
                            <div style={{ marginTop: 8 }}>Upload</div>
                        </div>
                    </div>
                </div>
            </Upload>
            <div style={{ width: "80%" }}>
                <Input
                    value={previewImage}
                    onChange={(value) => setPreviewImage(value)}
                    size="md"
                />
            </div>

            <div className="separator-horizontal" />

            <div style={{ width: "80%" }}>
                <span className="font-bold">Event Name</span>
                <div className="separator-horizontal" />
                <Input
                    value={eventName}
                    onChange={(value) => setEventName(value)}
                    size="md"
                />
                <div className="separator-horizontal" />

                <span className="font-bold">Event Location</span>
                <div className="separator-horizontal" />
                <Input
                    value={eventLocation}
                    onChange={(value) => setEventLocation(value)}
                    size="md"
                />
                <div className="separator-horizontal" />
                <span className="font-bold">Event Description</span>
                <div className="separator-horizontal" />
                <Input
                    value={description}
                    onChange={(value) => setDescription(value)}
                    placeholder="Description of the purpose of the event"
                    rows={5}
                />
                <div className="separator-horizontal" />

                <span className="font-bold">Event Dates</span>
                <div className="separator-horizontal" />
                <ThemeProvider theme={customTheme}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <div className="mb-2">
                        <span className="text-xs text-gray">
                          {startDate ? startDate.toLocaleString() : 'Not Selected'}
                        </span>
                      </div>
                      <DateTimePicker
                        value={startDate}
                        onChange={(date) => setStartDate(date)}
                        renderInput={(params) => <TextField {...params} />}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        dateFormat="MMM d, yyyy h:mm aa"
                        placeholder="Select Start Date"
                        label="Start Date"
                      />
                    </LocalizationProvider>
                </ThemeProvider>
                <div className="separator-horizontal" />

                <div className="separator-horizontal" />
                <ThemeProvider theme={customTheme}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <div className="mb-2">
                            <span className="text-xs text-gray ">{endDate ? endDate.toLocaleString() : 'Not Selected'}</span>
                        </div>
                        <DateTimePicker
                            value={endDate}
                            onChange={(date) => setEndDate(date)}
                            renderInput={(params) => <TextField {...params} />}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            dateFormat="MMM d, yyyy h:mm aa"
                            placeholder="Select End Date"
                            label="End Date"
                        />
                    </LocalizationProvider>
                </ThemeProvider>
                <div className="separator-horizontal" />

                <span className="font-bold">Event Location (Map)</span>
                <div className="separator-horizontal" />
                
                <div style={{ height: "300px", width: "840px", marginBottom: "20px" }}>
                    <MapContainer
                        center={[latitude || 51.505, longitude || -0.09]}
                        zoom={13}
                        style={{ height: "100%", width: "100%" }}
                    >
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        />
                        <MapClickHandler />
                        {latitude && longitude && <Marker position={[latitude, longitude]} />}
                    </MapContainer>
                    <div className="flex bg-gray-200 h-6">
                        <div className="flex flex-row center w-full">
                            <span className="mr-4">Latitude: </span>
                            <Input 
                                value={latitude}
                                onChange={(value) => setLatitude(value)}
                                className="w-2/3 bg-gray-200 h-1"
                            />
                        </div>
                        <div className="flex flex-row center w-full">
                            <span className="ml-4 mr-4">Longitude: </span>
                            <Input 
                                value={longitude}
                                onChange={(value) => setLongitude(value)}
                                className="w-2/3 bg-gray-200 h-1"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="separator-horizontal" />

            <div className="justify-end d-flex mt-8">
                <AttendeeModal attendees={attendees} />
                <InvitationModal invitations={invitations} allUsers={allUsers} eventData={eventData} />
                <div className="justify-end mt-3 mb-10">
                <div className="flex flex-row">
                    <button className="btnftn" onClick={attendees ? handleEditEvent : handlePostEvent}>
                        {attendees ? "Update Event" : "Post Event"}
                    </button>

                    {attendees && (
                        <button className="btnftn ml-3" onClick={handleDeleteEvent}>
                            Delete Event
                        </button>
                    )}
                </div>
                </div>
            </div>
        </div>
    );
};

export default EventEdit;
