import axios from "axios";
class UserApi {
  constructor() {
    this.userApiUrl = `/userauth`;
  }

  async getAllUsers() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}${this.userApiUrl}`);
      if (response.status >= 200 && response.status < 300) {
        return { data: response.data, status: true };
      } else {
        return { data: null, status: false };
      }
    } catch (error) {
      console.error(error);
      return { data: null, status: false };
    }
  }

  async loginUser(email, password) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}${this.userApiUrl}/authorize-user`,
        { headers: { Authorization: "Basic " + btoa(email + ":" + password) } }
      );
      //success
      if (response.status >= 200 && response.status < 300) {
        return {data: response.data, status:true};
      }
      else if(response.status === 401){
        return {data: response.data, status:false, message:"unauthorized"};
      }
      else{
        return {data: null, status:false};
      }

    } catch (error) {
      console.error("Login error:", error);
      return {data: null, status:false, message:"unauthorized"};
    }
  }

  async AddMember(firstName, lastName, emailAddress, username, phoneNumber, permissionLevel, password,signupType) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}${this.userApiUrl}/`,
        {
          firstName,
          lastName,
          emailAddress,
          username,
          phoneNumber,
          permissionLevel,
          password,
          signupType
        }
      );

      if (response.status >= 200 && response.status < 300) {
        return { data: response.data, status: true };
      } else {
        return { data: null, status: false };
      }
    } catch (error) {
      console.error("Adding user error:", error);
      return { data: null, status: false };
    }
  }

  getUserData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}${this.userApiUrl}/`);
      if (response.status >= 200 && response.status < 300) {
        return { data: response.data, status: true };
      } else {
        return { data: null, status: false };
      }
    } catch (error) {
      console.error(error);
      return {data: null, status: false};
    }
  };
  
  setIsMember = async (id, isMember) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_BASE_URL}${this.userApiUrl}/changeMemberType`, { id, isMember });
      if (response.status >= 200 && response.status < 300) {
        return { status: true };
      } else {
        return { status: false };
      }
    } catch (error) {
      console.error(error);
      return { status: false };
    }
  }
}

const userApi = new UserApi();
export default userApi;
