import React, { useState, useEffect } from "react";
import { Upload, message, notification } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Input } from "rsuite";
import { IoMdClose } from "react-icons/io";
import caseStudyApi from "../../api/CaseStudyApi";
import utilApi from "../../api/UtilApi";

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const CaseStudyEdit = ({ setDisplayCaseStudyView, caseStudyData }) => {
    const [fileList, setFileList] = useState([]);
    const [caseStudyId, setCaseStudyId] = useState(caseStudyData?.id || "");
    const [previewImage, setPreviewImage] = useState(caseStudyData?.imageUrl || "");
    const [companyName, setCompanyName] = useState(caseStudyData?.companyName || "");
    const [companyDetails, setCompanyDetails] = useState(caseStudyData?.companyDetails || "");
    const [fullCaseStudy, setFullCaseStudy] = useState(caseStudyData?.fullCaseStudy || "");
    const [summaryCaseStudy, setSummaryCaseStudy] = useState(caseStudyData?.summaryCaseStudy || "");
    const [videoUrl, setVideoUrl] = useState(caseStudyData?.videoUrl || "");
    const [pdf1Url, setPdf1Url] = useState(caseStudyData?.pdf1Url || "");
    const [pdf2Url, setPdf2Url] = useState(caseStudyData?.pdf2Url || "");

    useEffect(() => {
        setFileList([{ url: caseStudyData?.imageUrl }]);
    }, [caseStudyData]);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        const baseString = file.preview.split(",")[1];
        const imageUrl = await utilApi.convertBase64ToPng(baseString);
        if (imageUrl) {
            message.success("Image uploaded successfully!");
            setPreviewImage(imageUrl);
        }
    };

    const handleImageChange = async ({ fileList }) => {
        setFileList(fileList);
        if (fileList.length > 0) {
            const file = fileList[fileList.length - 1];
            handlePreview(file);
        }
    };

    const handleRemove = () => {
        setFileList([]);
        setPreviewImage("");
    };

    const handlePdfUpload = (file, setPdfUrl) => {
        const reader = new FileReader();
        reader.onload = async (e) => {
            const splitBase64 = e.target.result.split(",")[1];
            const pdfUrl = await utilApi.convertBase64ToPdf(splitBase64);
            if (pdfUrl) {
                setPdfUrl(pdfUrl);
                notification.success({
                    message: 'PDF Uploaded',
                    description: 'PDF uploaded successfully!',
                });
            } else {
                notification.error({
                    message: 'Upload Failed',
                    description: 'Failed to upload PDF.',
                });
            }
        };
        reader.readAsDataURL(file);
    };

    const missingValues = () => {
        if (!companyName || !companyDetails || !fullCaseStudy || !summaryCaseStudy || !previewImage || !pdf1Url || !pdf2Url || !videoUrl) {
            notification.error({
                message: 'Missing Information',
                description: 'Please fill in all the required fields before submitting.',
            });
            return true;
        }
        return false;
    };

    const handlePostCaseStudy = async () => {
        const missing = missingValues();
        if (missing) return;

        const updatedCaseStudyData = {
            companyName,
            companyDetails,
            fullCaseStudy,
            summaryCaseStudy,
            videoUrl,
            pdf1Url,
            pdf2Url,
            imageUrl: previewImage
        };

        try {
            const response = await caseStudyApi.createCaseStudy(updatedCaseStudyData);

            if (response) {
                notification.success({
                    message: 'Success',
                    description: 'Case study created successfully!',
                });
                setTimeout(() => {
                    handleCloseCaseStudyView();
                }, 4000);
            } else {
                notification.error({
                    message: 'Failure',
                    description: 'Failed to create case study.',
                });
            }
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'An error occurred while creating the case study.',
            });
        }
    };

    const handleEditCaseStudy = async () => {
        const missing = missingValues();
        if (missing) return;

        const updatedCaseStudyData = {
            id: caseStudyId,
            companyName,
            companyDetails,
            fullCaseStudy,
            summaryCaseStudy,
            videoUrl,
            pdf1Url,
            pdf2Url,
            imageUrl: previewImage
        };

        try {
            const response = await caseStudyApi.updateCaseStudy(caseStudyId, updatedCaseStudyData);
            if (response.success) {
                notification.success({
                    message: 'Success',
                    description: 'Case study updated successfully!',
                });
                setTimeout(() => {
                    handleCloseCaseStudyView();
                }, 4000);
            }
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'An error occurred while updating the case study.',
            });
        }
    };

    const handleDeleteCaseStudy = async () => {
        try {
            if (caseStudyData?.id) {
                const response = await caseStudyApi.deleteCaseStudy(caseStudyData.id);
                if (response.success) {
                    notification.success({
                        message: 'Success',
                        description: 'Case study deleted successfully!',
                    });
                    setTimeout(() => {
                        handleCloseCaseStudyView();
                    }, 4000);
                } else {
                    notification.error({
                        message: 'Failure',
                        description: 'Failed to delete case study.',
                    });
                }
            }
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'An error occurred while deleting the case study.',
            });
        }
    };

    const handleCloseCaseStudyView = () => {
        setDisplayCaseStudyView(false);
    };

    return (
        <>
            <div className="flex flex-row">
                <IoMdClose
                    className="cursor-pointer"
                    size={18}
                    onClick={handleCloseCaseStudyView}
                />
                <div className="separator-vertical" />
                <span className="text-2xl font-bold">{caseStudyData ? "Edit Case Study" : "Post Case Study"}</span>
            </div>

            <div className="separator-horizontal" />

            <span className="text-gray-600">
                {caseStudyData ? "Update the case study details" : "Create case studies for your users to see"}
            </span>

            <div className="separator-horizontal" />
            <div className="separator-horizontal" />

            <span className="font-bold">Cover</span>

            <div className="separator-horizontal" />

            <Upload
                listType="picture-card"
                fileList={fileList}
                onChange={handleImageChange}
                onRemove={handleRemove}
                beforeUpload={() => false}
                showUploadList={false}
            >
                <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                    {previewImage && (
                        <img
                            src={previewImage}
                            alt="Cover"
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                opacity: 0.7,
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                zIndex: 1,
                            }}
                        />
                    )}
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                            color: 'white',
                            fontSize: '20px',
                            backgroundColor: previewImage ? 'rgba(0, 0, 0, 0.1)' : '#2d9687',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            zIndex: 2,
                        }}
                    >
                        <div>
                            <PlusOutlined />
                            <div style={{ marginTop: 8 }}>Upload</div>
                        </div>
                    </div>
                </div>
            </Upload>
            <div style={{ width: "80%" }}>
                <Input
                    value={previewImage}
                    onChange={(value) => setPreviewImage(value)}
                    size="md"
                />
            </div>

            <div className="separator-horizontal" />

            <div style={{ width: "80%" }}>
                <span className="font-bold">Company Name</span>
                <div className="separator-horizontal" />
                <Input
                    value={companyName}
                    onChange={(value) => setCompanyName(value)}
                    size="md"
                />
                <div className="separator-horizontal" />

                <span className="font-bold">Company Details</span>
                <div className="separator-horizontal" />
                <Input
                    value={companyDetails}
                    onChange={(value) => setCompanyDetails(value)}
                    size="md"
                />
                <div className="separator-horizontal" />

                <span className="font-bold">Full Case Study</span>
                <div className="separator-horizontal" />
                <Input
                    value={fullCaseStudy}
                    onChange={(value) => setFullCaseStudy(value)}
                    size="md"
                />
                <div className="separator-horizontal" />

                <span className="font-bold">Summary Case Study</span>
                <div className="separator-horizontal" />
                <Input
                    value={summaryCaseStudy}
                    onChange={(value) => setSummaryCaseStudy(value)}
                    size="md"
                />
                <div className="separator-horizontal" />

                <span className="font-bold">Video URL</span>
                <div className="separator-horizontal" />
                <Input
                    value={videoUrl}
                    onChange={(value) => setVideoUrl(value)}
                    size="md"
                />
                <div className="separator-horizontal" />

                <span className="font-bold">PDF 1 URL</span>
                <div className="separator-horizontal" />
                <Input
                    value={pdf1Url}
                    onChange={(value) => setPdf1Url(value)}
                    size="md"
                />
                <div className="separator-horizontal" />
                <div className="pdf-test-section" style={{ width: '45%', height: '60%' }}>
                    <input 
                        name="Upload PDF"
                        type="file" 
                        onChange={(e) => handlePdfUpload(e.target.files[0], setPdf1Url)} 
                    />
                    <div className="separator-horizontal" />
                    {pdf1Url && (
                        <iframe src={pdf1Url} title="PDF 1" style={{ width: '100%', height: '100%' }} />
                    )}
                </div>
                <div className="separator-horizontal" />
                <span className="font-bold">PDF 2 URL</span>
                <div className="separator-horizontal" />
                <Input
                    value={pdf2Url}
                    onChange={(value) => setPdf2Url(value)}
                    size="md"
                />
                <div className="separator-horizontal" />
                <div className="pdf-test-section" style={{ width: '45%', height: '60%' }}>
                    <input type="file" onChange={(e) => handlePdfUpload(e.target.files[0], setPdf2Url)} />
                    <div className="separator-horizontal" />
                    {pdf2Url && (
                        <iframe src={pdf2Url} title="PDF 2" style={{ width: '100%', height: '100%' }} />
                    )}
                </div>
                <div className="separator-horizontal" />
            </div>
            <div className="separator-horizontal" />

            <div className="justify-end mt-3 mb-10">
                <div className="flex flex-row">
                    <button className="btnftn" onClick={caseStudyData ? handleEditCaseStudy : handlePostCaseStudy}>
                        {caseStudyData ? "Update Case Study" : "Post Case Study"}
                    </button>

                    {caseStudyData && (
                        <button className="btnftn ml-3" onClick={handleDeleteCaseStudy}>
                            Delete Case Study
                        </button>
                    )}
                </div>
            </div>
        </>
    );
};

export default CaseStudyEdit;
