import React, { useState, useEffect } from "react";
import { Panel, Pagination, Row, Col, Modal } from "rsuite";
import "./style.css";
import { Table, Popover, Whisper, Dropdown, IconButton,Checkbox } from "rsuite";
import MoreIcon from "@rsuite/icons/legacy/More";
import { InputGroup } from "rsuite";
import SearchIcon from "@rsuite/icons/Search";
import { IoAddOutline } from "react-icons/io5";
import { Form, Input, Button } from "antd";
import toast, { Toaster } from "react-hot-toast";
import userApi from "../../api/UserApi";

const Members = () => (
  <Panel className="bg-white" bordered header="Members">
    <div>
      <text>Number of members added </text>
      <div className="separator-horizontal" />
      <div className="datacontainer">
        <text className="text-base">0</text>
        <div className="textcontainer">
          <text>Active</text>
        </div>
      </div>
    </div>
  </Panel>
);

const PrivateMembers = () => (
  <Panel className="bg-white" bordered header="Private Members">
    <div>
      <text>Number of internal memebers</text>
      <div className="separator-horizontal" />
      <div className="datacontainer">
        <text className="text-base">0</text>
        <div className="textcontainer">
          <text>Active</text>
        </div>
      </div>
    </div>
  </Panel>
);

const PublicMembers = () => (
  <Panel className="bg-white" bordered header="Public Members">
    <div>
      <text>Number of users on the app</text>
      <div className="separator-horizontal" />
      <div className="datacontainer">
        <text className="text-base">0</text>
        <div className="textcontainer">
          <text>Active</text>
        </div>
      </div>
    </div>
  </Panel>
);

const { Column, HeaderCell, Cell } = Table;

const CheckCell = ({ rowData, onChange, checkedKeys, dataKey, ...props }) => (
  <Cell {...props} style={{ padding: 0 }}>
    <div style={{ lineHeight: "46px" }}>
      <Checkbox
        value={rowData[dataKey]}
        inline
        onChange={onChange}
        checked={checkedKeys.some((item) => item === rowData[dataKey])}
      />
    </div>
  </Cell>
);

const renderMenu = ({ onClose, left, top, className }, ref, rowData) => {
  const handleSelect = (eventKey) => {
    onClose();
  };
  return (
    <Popover ref={ref} className={className} style={{ left, top }} full>
      <Dropdown.Menu onSelect={handleSelect}>
        <Dropdown.Item eventKey={1}>Edit Member</Dropdown.Item>
        <Dropdown.Item eventKey={2}>Remove Member</Dropdown.Item>
      </Dropdown.Menu>
    </Popover>
  );
};

const ActionCell = ({ rowData, dataKey, ...props }) => {
  return (
    <Cell style={{ padding: 0 }} {...props} className="link-group">
      <Whisper
        placement="autoVerticalStart"
        trigger="click"
        speaker={(popoverProps, ref) => renderMenu(popoverProps, ref, rowData)}
      >
        <IconButton appearance="subtle" icon={<MoreIcon />} />
      </Whisper>
    </Cell>
  );
};

export const Home = () => {
  const [data, setdata] = useState([]);
  const [initialdata, setinitialdata] = useState([]);

  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = () => {
    const filteredData = initialdata.filter(item =>
      Object.values(item).some(value =>
        value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setdata(filteredData);
  };
  

  const fetchData = async () => {
    try {
      const response = await userApi.getUserData();
      if (response.status) {
        setdata(response.data);
        setinitialdata(response.data);
      } else {
        console.error("Failed to fetch table data");
        toast.error("Failed to fetch table data");
      }
    } catch (error) {
      console.error("Failed to fetch table data", error);
      toast.error("Failed to fetch table data", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [checkedKeys, setCheckedKeys] = React.useState([]);
  const [formData, setFormData] = useState({
    username: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: "",
  });

  let checked = false;
  let indeterminate = false;

  if (checkedKeys.length === data.length) {
    checked = true;
  } else if (checkedKeys.length === 0) {
    checked = false;
  } else if (checkedKeys.length > 0 && checkedKeys.length < data.length) {
    indeterminate = true;
  }

  const handleCheckAll = (value, checked) => {
    const keys = checked ? data.map((item) => item.id) : [];
    setCheckedKeys(keys);
  };
  const handleCheck = (value, checked) => {
    const keys = checked
      ? [...checkedKeys, value]
      : checkedKeys.filter((item) => item !== value);
    setCheckedKeys(keys);
  };

  const [page, setPage] = React.useState(1); // State variable for current page
  const pageSize = 5; // Number of items per page
  const startIdx = (page - 1) * pageSize;
  const endIdx = startIdx + pageSize;

  const handlePageChange = (nextPage) => {
    setPage(nextPage);
  };

  //modal

  const [open, setOpen] = React.useState(false);
  const handleOpen = (value) => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const generatePassword = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let password = "";
    for (let i = 0; i < 8; i++) {
      password += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return password;
  };

  const onFinish = async (values) => {
    const { phoneNumber, emailAddress, lastName, firstName, username } = values;
    const password = generatePassword();
    try {
      const response = await userApi.AddMember(
        firstName,
        lastName,
        emailAddress,
        username,
        phoneNumber,
        0,
        password,
        "dash"
      );

      if (response.status) {
        toast.success("Member Added Successfully.");
        handleClose();
      } else {
        toast.error("Failed to add member.");
      }
    } catch (error) {
      console.error("Add member error:", error);
    }

    fetchData();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // membership checkbox

  const [checkeditem, setCheckeditem] = useState(true);

  const toggleChecked = async (id, isMember) => {
    try {
      const response = await userApi.setIsMember(id, !isMember);
      if (response.status) {
        toast.success("Member type changed successfully");
      } else {
        toast.error("Failed to change member type");
      }
    } catch (error) {
      console.log("error while changing member type");
      toast.error("error while changing member type");
    }

    fetchData();
  };

  const mdinp = {
    borderRadius: "5px",
  };
  return (
    <div className="contentcontainer">
      <text className="text-2xl font-bold">Home</text>

      <div className="separator-horizontal" />

      <text className="text-gray-600">Keep up to date with the members </text>

      <div className="separator-horizontal" />

      <Row>
        <Col md={6} sm={12}>
          <Members />
        </Col>
        <Col md={6} sm={12}>
          <PrivateMembers />
        </Col>
        <Col md={6} sm={12}>
          <PublicMembers />
        </Col>
      </Row>

      <div className="separator-horizontal" />

      <div className="tblcontainer">
        <div className="hdcontainer">
          <text className="font-bold text-2lg">
            {data.length} members found
          </text>
          <div className="separator-vertical" />
          <InputGroup
            className="border-3"
            inside
            style={{ width: "30%", paddingLeft: 2 }}
          >
            <input className="inpsearch" placeholder="Search your members " value={searchTerm} onChange={e => setSearchTerm(e.target.value)} />
            <InputGroup.Button onClick={handleSearch}>
              <SearchIcon />
            </InputGroup.Button>
          </InputGroup>

          <div className="separator-vertical" />

          {/* <button className="btnadd" onClick={() => handleOpen()}>
            {" "}
            <IoAddOutline color="white" />
            Add member
          </button> */}
        </div>
        <Table hover={false} height={300} data={data} id="table">
          <Column flexGrow={1} align="center">
            <HeaderCell style={{ padding: 0 }}>
              <div style={{ lineHeight: "40px" }}>
                <Checkbox
                  inline
                  checked={checked}
                  indeterminate={indeterminate}
                  onChange={handleCheckAll}
                />
              </div>
            </HeaderCell>
            <CheckCell
              dataKey="id"
              checkedKeys={checkedKeys}
              onChange={handleCheck}
            />
          </Column>

          <Column flexGrow={2} align="center">
            <HeaderCell>Username</HeaderCell>
            <Cell>{(rowData) => rowData.username}</Cell>
          </Column>

          <Column flexGrow={2} align="center">
            <HeaderCell>First name</HeaderCell>
            <Cell>{(rowData) => rowData.firstName}</Cell>
          </Column>

          <Column flexGrow={2} align="center">
            <HeaderCell>Last name</HeaderCell>
            <Cell>{(rowData) => rowData.lastName}</Cell>
          </Column>

          <Column flexGrow={2} align="center">
            <HeaderCell>Email</HeaderCell>
            <Cell>{(rowData) => rowData.emailAddress}</Cell>
          </Column>

          <Column flexGrow={2} align="center">
            <HeaderCell>Phone</HeaderCell>
            <Cell>{(rowData) => rowData.phoneNumber}</Cell>
          </Column>

          <Column flexGrow={2} align="center">
            <HeaderCell>Membership</HeaderCell>
            <Cell style={{ padding: 0 }}>
              {(rowData) => (
                <Checkbox
                  onChange={() => toggleChecked(rowData.id, rowData.isMember)}
                  value={rowData.isMember}
                  checked={rowData.isMember}
                />
              )}
            </Cell>
          </Column>

          <Column flexGrow={2} align="center">
            <HeaderCell>Signup type</HeaderCell>
            <Cell >{(rowData) => rowData.signupType}</Cell>
          </Column>

          <Column flexGrow={1} align="center">
            <HeaderCell>
              <MoreIcon />
            </HeaderCell>
            <ActionCell dataKey="id" />
          </Column>
        </Table>
        <div>
          {/* TODO: move to component */}
          <Modal size={"sm"} open={open} onClose={handleClose}>
            <Modal.Header>
              <Modal.Title className="mb-2">Add Member</Modal.Title>
              <text className="text-gray-600">
                You can add members to your workspace
              </text>
            </Modal.Header>
            <Modal.Body>
              <div style={{ width: "80%" }}>
                <Form
                  name="basic"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  style={{ maxWidth: 600 }}
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  className="form"
                >
                  <Form.Item
                    label="Username"
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Please input your username!",
                      },
                    ]}
                  >
                    <Input className="mdinput" style={mdinp} />
                  </Form.Item>

                  <Form.Item
                    label="First Name"
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your first name!",
                      },
                      {
                        pattern: /^[A-Za-z]+$/,
                        message: "Please enter a valid name.",
                      },
                    ]}
                  >
                    <Input className="mdinput" style={mdinp} />
                  </Form.Item>

                  <Form.Item
                    label="Last Name"
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your last name!",
                      },
                      {
                        pattern: /^[A-Za-z]+$/,
                        message: "Please enter a valid name.",
                      },
                    ]}
                  >
                    <Input className="mdinput" style={mdinp} />
                  </Form.Item>

                  <Form.Item
                    label="Email address"
                    name="emailAddress"
                    rules={[
                      {
                        required: true,
                        message: "Please input your email!",
                        type: "email",
                      },
                    ]}
                  >
                    <Input className="mdinput" style={mdinp} />
                  </Form.Item>

                  <Form.Item
                    label="Phone Number"
                    name="phoneNumber"
                    rules={[
                      {
                        required: true,
                        message: "Please input your phone number!",
                      },
                      {
                        pattern:
                          /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                        message: "Please enter a valid phone number.",
                      },
                    ]}
                  >
                    <Input className="mdinput" style={mdinp} />
                  </Form.Item>

                  <Form.Item
                    style={{ paddingLeft: "73%" }}
                    wrapperCol={{ offset: 8, span: 16 }}
                  >
                    <Button
                      id="btnmd"
                      className=" text-white p-4 flex items-center justify-center rounded-md"
                      htmlType="submit"
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
        </div>
      </div>
      <Toaster />
    </div>
  );
};
